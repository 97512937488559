<template>
  <Main
    v-if="loading"
    :service-status-list="serviceStatusList"
    type="modelling"
    name="造型"
    :tab-list="tabList"
    base-service-type="4"
  />
</template>

<script>
import Main from '../main.vue'

export default {
  components: {
    Main,
  },
  data() {
    return {
      tabList: [
        { name: '全部造型服务列表', status: 1, show: false },
        { name: '超时未接单', status: 2, statusCount: 0, show: false },
        { name: '我的造型服务列表', status: 3, statusCount: 0, show: true },
      ],
      serviceStatusList: [
        { name: '待派单', val: 0 },
        { name: '待接单', val: 1 },
        { name: '已拒绝', val: 7 },
        { name: '待服务', val: 2 },
        { name: '服务中', val: 3 },
        { name: '待评价', val: 4 },
        { name: '已评价', val: 5 },
        { name: '售后中', val: 8 },
        { name: '已关闭', val: 9 },
      ],
      loading: false,
    }
  },
  mounted() {
    const btns = JSON.parse(window.sessionStorage.getItem('btnPermissions'))
    if (btns && btns.find((e) => e.nodeCode === 'GY-SERVICE-MODELING-ALL'))
      this.tabList[0].show = true
    if (btns && btns.find((e) => e.nodeCode === 'GY-SERVICE-MODELING-NOTORDER'))
      this.tabList[1].show = true
    this.loading = true
  },
}
</script>

<style lang="less" scoped></style>
